
h1, h2, h3{
  font-family: 'Roboto', sans-serif!important;
  font-weight: bold!important;
}

h4, h5, h6{
  font-family: 'Roboto', sans-serif!important;
  font-weight: 500!important;
}

p{
  font-family: 'Roboto', sans-serif!important;
}

.main-content-container, body,.main-content,.container-fluid, #root{
  background-color: #ffffff!important;

}

.screen-rondo{
  margin-top: 10%;

}

.log-block{
  background-color: #e0e0e0;
  margin-top: 2%;
  margin-left: 10%!important;
  padding: 2% 3%;
  border-left-style: solid;
  border-left-width: 3px;
  border-left-color: #e94e1b;
}

.title{
  font-weight: bold;
  margin-top: 2%!important;
  margin-bottom: 2%!important;
}

.log-block a{
  color: white!important;
  background-color: #e94e1b;
  padding: 0.8% 1.5%;
  font-size: 13px;
  margin-top: 4%;
  border-radius: 51px;
}
.log-block a:hover{
  color: white!important;
  background-color: #be4218;
  padding: 0.8% 1.5%;
  font-size: 13px;
  margin-top: 4%;
  border-radius: 51px;
}

.pie{
  display: block;
  height: 219%!important;
  width: 64%!important;
  position: absolute!important;
  margin-top: 7.8%!important;
  margin-left: 15.8%!important;
}

.great-doughnut{
  display: block;
  height: 185%!important;
  width: 185%!important;
  position: absolute!important;
  z-index: 0!important;
  margin-left: -44.7%!important;
  margin-top: -22.5%!important;
}

.middle-doughnut{
  height: 100%!important;
  width: 125.2%!important;
  position: absolute;
  margin-left: -14.8%;
  margin-top: -7.5%;
}

.reservation-block{
  background-color: #f2f2f2!important;
  padding: 5%;
  margin-bottom: 10%;
  border-radius: 5px;
  height: 220px;
  border-style: solid;
  border-width: 1px;
  border-color: black;

}

.reservation-block-image{
  padding: 5%;
  margin-bottom: 2%;
  border-radius: 5px;
  height: 200px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  background-repeat: no-repeat;
  background-size: 260px 200px;
}

.two-block-white{
  background-color: white;
  border-width: 1px;
  padding: 4% 4% 4% 4%;
  border-style: solid;
  border-color: black;
  border-radius: 5px;
  margin-bottom: 4%;
}


.four-block-white{
  background-color: white;
  padding: 4%;
  border-style: solid;
  border-color: black;
  border-radius: 5px;
  margin-bottom: 4%;
  border-width: 1px;

}

.one-block-white{
  background-color: white;
  padding: 4%;
  border-style: solid;
  border-color: black;
  border-radius: 5px;
  margin-bottom: 4%;
  border-width: 1px;

}

.one-block-white li{
list-style: none;
}

.one-block-white h4{
  font-weight: bolder;
}

.one-block-white input, .one-block-white textarea{
  border-radius: 10px;
  padding: 2%;
  border-width: 1px;
}
.status{
float: right;
color:#FFB100;
}

.action{
  float: right;
  margin-right: 0%;
}

.table-head{
  font-weight: bold;
}

.status-head{
  float: right;

  }

  .add{
    margin-right: 8%;
  }


  .post-view-col{
    height: 200%;
    
  }
  

  .post-it-view{
    position: relative;
    background-color: white;
    padding: 4%;
    top: 10%;
    left: 25%;
    font-size: 50px;

    width: 50%;
    border-radius: 5px;
    margin-bottom: 10%!important;
  }

  .post-it-view input,.post-it-view textarea,.post-it-view select{
    width: 100%;
    margin-bottom: 4%;
    font-size: 14pt;
  }
  .post-it-view textarea{
    height: 15vh;
    margin-bottom: -5%;
  }

  .post-it-update{
    font-size: 10pt;
    color: green;
    padding-top: 5.8%!important;
    float: right;
  }

  .post-it-close{
    font-size: 10pt;
    text-decoration: underline;
    float: left;
  }

  .post-it-delete{
    font-size: 10pt;
    text-decoration: underline;
    float: right;
    color: red;
  }

  .post-it-view h6{

    margin-bottom: 0%;
  }

  .post-it-textarew{
    margin-bottom: 0%!important;
  }

 label{
    font-size: 12pt!important;
  }

  [type="checkbox"]
{
    vertical-align:middle;
}
  
  .action-head{
    float: right;
    margin-right: 0%;
  }

  .one-block-white input, .one-block-white textarea{
    width: 100%;
    margin-bottom: 2%;
    font-size: 14pt;
  }


.two-block-blue{

  background-repeat: no-repeat;
  background-size: cover;
  background-color: #ED6A1C;
  border-width: 1px;
  padding: 4%;
  border-style: solid;
  border-color: black;
  border-radius: 5px;
  margin-bottom: 3.8%;
  width: 100%;
}

.overlay{
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 998; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}



.scrum-image{
  width: 71%;
}

.scrum-title{
  color:white;
}

.scrum-date{
  font-size: 32pt;
  color: white;
  margin-bottom: 5%;
}

.ffort-button{
  width: 40%;
  border-radius: 50px!important;
  background-color: #e94e1b!important;
  border-style: none!important;
}

.ffort-button:hover{
  background-color: rgba(255, 166, 0, 0.637)!important;
}

.ffort-button-white{
  width: 40%;
  border-radius: 50px!important;
  background-color: white!important;
  border-style: none!important;
  color:black!important;
}

.ffort-button-white:hover{
  background-color: rgba(255, 166, 0, 0.637)!important;
}

.count-number{
  font-size: 45pt;
}

.text-align-center{
  text-align: center;
}


.sprint-date{
  font-size: 22pt;
}



.apm-board{
  height: 1080px!important;
  width: 1920px!important;
  margin-top: 5%;
}

.my-desk-block{
  background-color: #f2f2f2!important;
  padding: 5%;
  margin-top: 14%;
  border-radius: 5px;
  height: 70vh;
  border-style: solid;
  border-width: 1px;
  border-color: black;
}

.box{
  width: 130px;
  padding: 3%;
  margin-bottom: 2%;
  border-radius: 2px;
  box-shadow: 0 24px 50px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  color: white;
  border-style:solid;
  border-width: 1px;
  border-radius: 5px; 
}

.box:hover{
  cursor: pointer;
}

.error-text{
  color:red;
}

.one-block-white select{
  width: 100%;
}

.add-new-post__editor .ql-container,
.add-new-post__editor .ql-editor {
  min-height: 400px;
}

.nav-ffort .nav-item .nav-link{
  color: black!important;
}
.nav-ffort .nav-item .nav-link .material-icons{
  color: black!important;
}

.nav-link.active{
  border-left-color: #ED6A1C!important;
  border-left-width: 1px!important;
}

.nav-wrapper{
  background-color: #f2f2f2!important;
}

.min-twee{
  background-image: url('/assets/images/background-dummy.jpg');
}

.settingsBlocks{
  background-color: #ED6A1C;
  padding: 10% 5% 10% 5%;
  border-radius: 20px;
  margin-bottom: 10%;
}
.active-block{
  opacity: 0.5;
  background-color: #ED6A1C;
  padding: 10% 5% 10% 5%;
  border-radius: 20px;
}

.active-block a{
  color: white!important;
  display: flex;
  justify-content: center;

}

.settingsBlocks a{
  color: white!important;
  text-align: center;
  display: flex;
  justify-content: center;

}

.settingsBlocks i{
  color: white;
  font-size: 24pt;
  display: flex;
  justify-content: center;
}

.settingsBlocksCat i{
  color: white;
  font-size: 24pt;
  display: flex;
  justify-content: center;
}

.settingsBlocksCat{
  background-color: #ED6A1C;
  padding: 20% 5%;
  border-radius: 20px;
  width: 90%;
  height: 90%;
  overflow-wrap: break-word;
}


.version-control{
z-index: 999;
margin-top: -7%;
  position: absolute;
  left: 3;
  margin-left: 2%;

font-weight: bold;
}
.logout-button{
  position: absolute;
    bottom: 0;
    left: 0;
  font-weight: bold;
  padding: .9375rem 1.5625rem;
  background-color: #e94e1b;
  width: 100%;
  color: white;
}

.logout-button:hover{
  background-color: #FF8136;
}
.titles-row{
  margin-bottom: 2%;
}

.text-middle{
  text-align: center;
  margin-top: -24%;
  margin-bottom: 24%;
}

.title-middle{
  text-align: center;
}




.image-steps{
  width: 40%;
  margin-left: 30%;
  margin-top: 5%;
}

.logo-ffort{
  width: 30%;
  margin-top: 10%;
  margin-bottom: 5%;  
  margin-left: 35%;

}

.delted-action i{
  margin-left: 50%;
  color: red;
}

.delted-action i:hover{
  opacity: 0.5;
}
.image-steps-title{
  text-align: center;
  margin-top: 13%;
  margin-bottom: 13%;
  color: white;
  font-weight: bold;

}

.input-field-sets{
  width: 100%;
  border-bottom-style: solid;
  border-left: none;
  border-right: none;
  border-top: none;
  padding: 2%;
  border-bottom-color: grey;
  border-bottom-width: 1px;
}

.image-steps-select{
color: white;
}

.bord-settings{
margin-top: 2%;
}

.titles-setup{
  font-weight: bold;

}

.button-setup{
  background-color: #ED6A1C!important;
    border-radius: 50px!important;
    padding: 2% 8%!important;

    font-weight: bold!important;
    border-color: #ED6A1C!important;
    
}

.button-reset{
  background-color: #ED6A1C!important;
    border-radius: 50px!important;
    padding: 2% 8%!important;

    font-weight: bold!important;
    border-color: #ED6A1C!important;
    margin-bottom: 5%;
}

.participants{
  margin-top: 5%;
  margin-bottom: -5%!important;
}

.button-cat{
  background-color: #ED6A1C!important;
  border-radius: 50px!important;
  font-weight: bold!important;
  border-color: #ED6A1C!important;
  width: 100%;
  margin-top: 15%;
  padding: 4% 8%!important;
}

.button-add{
  background-color: #ED6A1C!important;
    border-radius: 50px!important;
    padding-left: 8%!important;
    padding-right: 8%!important;
    font-weight: bold!important;
    border-color: #ED6A1C!important;
    width: 100%;
    margin-top: 15%;
}

.button-add-category{
  background-color: #ED6A1C!important;
    border-radius: 50px!important;
    padding-left: 8%!important;
    padding-right: 8%!important;
    font-weight: bold!important;
    border-color: #ED6A1C!important;
    width: 30%;
    margin-top: 3%;
}

.link-setup{
  background-color: #ED6A1C!important;
  border-radius: 50px!important;
  padding-left: 8%!important;
  padding-right: 8%!important;
  padding: 1%;
  font-weight: 700!important;
  border-color: #ED6A1C!important;
  color: white!important;
  font-size: 9pt;
}

.link-setup-2{
  background-color: #ED6A1C!important;
  border-radius: 50px!important;
  padding-left: 8%!important;
  padding-right: 8%!important;
  padding: 2%;
  font-weight: 700!important;
  border-color: #ED6A1C!important;
  color: white!important;
  font-size: 9pt;
}

.link-setup-afronden{
  background-color: #ED6A1C!important;
  border-radius: 50px!important;
  padding-left: 8%!important;
  padding-right: 8%!important;
  padding: 2.5%;
  font-weight: 700!important;
  border-color: #ED6A1C!important;
  color: white!important;
  font-size: 9pt;
  width: 75%;
  text-align:  center;
}

.link-setup-categorie{
  background-color: #ED6A1C!important;
  border-radius: 50px!important;
  padding-left: 8%!important;
  padding-right: 8%!important;
  padding: 1%;
  font-weight: 700!important;
  border-color: #ED6A1C!important;
  color: white!important;
  font-size: 9pt;
  width: 75%;
  text-align:  center;
  margin-top: 5%!important;
}

.button-adding{
  margin-top: 2%;
}

.button-sorting{
  background-color: #ED6A1C!important;
  border-radius: 50px!important;
  padding-left: 10%!important;
  padding-right: 10%!important;
  font-weight: bold!important;
  padding-top: 3%!important;
  padding-bottom: 3%!important;
  width: 100%;
 
}

.bord-settings input{
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid;
  border-bottom-width: 1px;
  padding: 2%;
  width: 50%;
}

.bord-settings li{
  list-style: none;
  border-bottom-style: solid;
  width: 80%;
  padding-bottom: 2%;
  border-bottom-width: 1px;
  margin-bottom: 2%;
}
.archive-list{
  margin-bottom: 5%;
}

.error-message{
  background-color: rgba(255, 0, 0, 0.8);
  padding: 3%;
  padding-bottom: 0.2%;
  border-radius: 20px;
  color: white;
  margin-top: 3%;
}

.archive-list h6{
  padding-bottom: 1%;
  margin-top: 5%;

}

.archive-list li{
  list-style: none;
  border-bottom-style: solid;
  width: 100%;
  padding-bottom: 1%;
  border-bottom-width: 1px;
  margin-top: 1%;
}

.bottom-col{
  margin-bottom: 10%;
}

.modal-background{
  height: 100vh;
  background-size: cover;
}

.start-block{
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  margin-top: 10%;
  padding-bottom: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-block-left{

  background-color: white;
  border-style:solid;
  border-width: 1px;
  border-radius: 20px;
  margin-top: 8%;
  padding-bottom: 3%;
  padding-top: 2.5%;
  border-right-style: solid;
  margin-bottom: 3%;
}

.login-block-left h3{
  text-align: center;
  font-weight: bolder;
  font-family: 'Montserrat' ;
}

.login-block-left h4{
  text-align: center;
  font-family: 'Montserrat' ;
}

.login-block-left p{
  text-align: center;
  font-family: 'Montserrat' ;
  font-weight: 300;
}

.login-block-left input{
  width: 70%;
  margin-left: 15%;
  padding:1%;
  border-radius: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #707070;
  padding-left: 3%;
}

.login-block-left button{
  margin-left: 13%;
  width: 74%;
  padding: 2%;
}

.login-block-right{
  background-color: white;
  background-image: url('./images/background-shop.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center; /* Center the image */
  border-right-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: none;
  border-width: 1px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-top: 8%;
  padding-bottom: 5%;
  display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
}

.question-block{
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  margin-top: 5%;
  padding-bottom: 3%;
 
}

.sorting-row{
  padding-left: 14%;
}
.sorting-block{
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  margin-top: 5%;
  padding-top: 1%;
  padding-bottom: 3%;
 width: 90%;
 margin-right: 0.15%;

}

.sorting-block h5{
  margin-bottom: 5%;
  font-weight: bold;
  font-size: 21pt;
  border-bottom: solid;
  padding-bottom: 3%;
  
}

.sorting-block li{
  list-style: none;
  border-bottom-style: solid;
  width: 80%;
  padding-bottom: 2%;
  border-bottom-width: 1px;
  margin-bottom: 2%;
}

.block-middle{
  padding-top: 5%!important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block-middle-stelling{
  padding-top: 2%!important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block-middle-stelling h5{
  font-weight: bolder;
  font-style: italic;
}

.block-middle-stelling-qsort{
  padding-top: 1%!important;
  padding-bottom: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block-middle-stelling-qsort h5{
  font-weight: bolder;
  font-style: italic;
}
.block-middle h5{
  font-weight: bolder;
}

.block-middle h6{
  text-align: center;
}
.block-middle-text{
  margin-top: 2%;
  text-align: center;
}

.block-middle-text p{
  margin-bottom: 3%;
  padding-left: 10%;
  padding-right: 10%;
}

.block-middle-text-question{
  margin-top: 2%;
  text-align: center;
}
.block-middle-text-question p{
  margin-bottom: 3%;
  padding-left: 10%;
  padding-right: 10%;
  font-size: 18pt;
}

.block-middle-text-left{
padding-top: 5%;
}

.disagrees-table{
  border-color: #ED6A1C;
  border-width: 3px;
}

.disagrees-table h5{
  border-color: #ED6A1C;
}

.naturals-table{
  border-color: #ED6A1C;
  border-width: 3px;
  background-color: #F2B28C;
  color: white;
}

.naturals-table h5{
  border-color: #ED6A1C;
  color: white;
}
.agrees-table{
  border-color: #ED6A1C;
  border-width: 3px;
  background-color: #ED6A1C;
  color: white;
}

.marginbottom{
  margin-bottom: 5%;
}

.agrees-table h5{
  border-color: white;
  color: white;
}


.image-check{
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category{
  color: #ED6A1C;
  font-weight: bold;
  margin-left: 2%;
  margin-right: 2%;
}

.date-list{
  margin-left: 2%;
  margin-right: 2%;
}

.see-list{
  float: right;
  margin-right: 2%;
}

.see-list-delete i{
  float: right;
  margin-right: 1%;
  color:#FF0000;
  margin-top: 5.5%;
}

.see-list-delete a{
  float:right;
  text-decoration: underline!important;
}

.see-list-delete:hover a{
  color:#FF0000!important;
}

.see-list-edit i{
  float: right;
  margin-right: 1%;
  margin-top: 5.5%;
}

.question-list{
  margin-right: 2%;

}

.question-items{
  margin-right: 2%;
  margin-left: 2%;
}

.select-box-bord{
  width: 50%;
  background-color: white;
  border-radius: 0px!important;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 2%;
}

.nav-ffort .nav-item .nav-link.active, .nav-ffort .nav-item .nav-link:hover{
box-shadow: inset 0.1875rem 0 0 #ED6A1C!important;
}

.active, .nav-link:hover{
  background-color: #c0632d71!important;
}

.custom-Class div{
  width: 100%!important;
}

.position-title{
  border-bottom-style: solid;
  border-bottom-width: 2px;

  margin-bottom: 5%;
}
.position-title h3{
  text-align: center;
  background-color: white;
  margin-bottom: 4%!important;
}

.position-positive div{
  background-color: #ED6A1C;
}


  .one{
    background-image: url('./images/background-1-groot.jpg');
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center; /* Center the image */
    height: 18vh;
  }
  
  .two{
    background-image: url('./images/background-2-groot.jpg');
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center; /* Center the image */
    height: 27vh;
  }
  
  .three{
    background-image: url('./images/background-3-groot.jpg');
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center; /* Center the image */
    height: 35vh;
  }

  .position-negative div{
    background-color: white!important;
    padding: 0px!important;
  
  }
  
  .position-negative div div{
    padding: 10px!important;
    border-color: #ED6A1F!important;
    border-style: solid;
    border-width: 2px;
    padding: 17px!important;
  }
  
  .position-natural div{
    background-color: #F2B28C;
    padding: 0px!important;
  }
  
  .position-natural div div{
    background-color: #F2B28C!important;
    color: white!important;
    padding: 17px!important;
    border-color: #ED6A1F!important;
    border-style: solid;
    border-width: 2px;
  }
  
  .position-positive div{
    background-color: #ED6A1F;
    padding: 0px!important;
  }
  
  .position-positive div div{
    background-color: #ED6A1F!important;
    color: white!important;
    padding: 17px!important;
    border-color: #ED6A1F!important;
    border-style: solid;
    border-width: 2px;
  }
@media only screen and (max-width: 1280px) {
.one{
  background-image: url('./images/background-1.jpg');
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center; /* Center the image */
  height: 18vh;
}

.two{
  background-image: url('./images/background-2.jpg');
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center; /* Center the image */
  height: 27vh;
}

.three{
  background-image: url('./images/background-3.jpg');
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center; /* Center the image */
  height: 35vh;
}

.position-negative div{
  background-color: white!important;
  padding: 0px!important;

}

.position-negative div div{
  padding: 10px!important;
  border-color: #ED6A1F!important;
  border-style: solid;
  border-width: 2px;

}

.position-natural div{
  background-color: #F2B28C;
  padding: 0px!important;
}

.position-natural div div{
  background-color: #F2B28C!important;
  color: white!important;
  padding: 10px!important;
  border-color: #ED6A1F!important;
  border-style: solid;
  border-width: 2px;
}

.position-positive div{
  background-color: #ED6A1F;
  padding: 0px!important;
}

.position-positive div div{
  background-color: #ED6A1F!important;
  color: white!important;
  padding: 10px!important;
  border-color: #ED6A1F!important;
  border-style: solid;
  border-width: 2px;
}

.login-block-left{
  background-color: white;
  border-left-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  border-right-style: none;
  border-width: 1px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-top: 5%;
  padding-bottom: 3%;
  padding-top: 2.5%;
}
.login-block-right{
  background-color: white;
  background-image: url('./images/login-image.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center; /* Center the image */
  border-right-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: none;
  border-width: 1px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-top: 5%;
  padding-bottom: 5%;
  display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
}
}



.class-group-natural{
  background-color: #F2B28C;
  width: 60%;
  margin-right: 5%;
  margin-left: 1%;
  border-radius: 20px;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 2%;
  padding-bottom: 2%;
  border-color: #ED6A1C;
  border-width: 2px;
  border-style: solid;
}

.class-group-natural h4,.class-group-agree h4{
  color: white!important;
  font-weight: bold;
  margin-top: -2%;
}

.class-group-disagree h4{
  font-weight: bold;
  margin-top: -2%;
}

.class-group-natural div{
  background-color: #F2B28C!important;
  padding: 0px!important;
}

.class-group-natural div{
  background-color: none!important;
  width: 100%!important;
}

.class-group-natural div div{
  background-color: #F2B28C!important;
  padding: 3px!important;
  border-bottom-style: solid!important;
  border-bottom-width: 1px!important;
  border-bottom-color: white!important;
  color: white!important;
  max-width: 450px;
}

.class-group-disagree{
  background-color: white;
  width: 60%;
  margin-right: 5%;
  margin-left: 5%;
  border-radius: 20px;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 2%;
  padding-bottom: 2%;
  border-color: #F2B28C;
  border-width: 2px;
  border-style: solid;
}

.class-group-disagree div{
  background-color: white!important;
  width: 100%!important;
  padding: 0px!important;
}

.class-group-disagree div div{
  background-color: white!important;
  padding: 3px!important;
  border-bottom-style: solid!important;
  border-bottom-width: 1px!important;
  max-width: 450px;
}

.class-group-agree{
  background-color: #ED6A1C;
  width: 60%;
  margin-right: 5%;
  margin-left: 1%;
  border-radius: 20px;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 2%;
  padding-bottom: 2%;
  border-color: #ED6A1C;
  border-width: 2px;
  border-style: solid;
}

.class-group-agree div{
  background-color: #ED6A1C!important;
  width: 100%!important;
}

.class-group-agree div div{
  background-color: #ED6A1C!important;
  padding: 3px!important;
  border-bottom-style: solid!important;
  border-bottom-width: 1px!important;
  color: white!important;
  max-width: 450px;
}


.background-white{
  background-color: white!important;
}

.Drops{
  margin-top: 5%;
  margin-left: 6%!important;
  width: 100%;
  margin-right: 8%;
}

.qsortdone{
  border-style: solid;
  border-color: black;
  border-width: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;
  border-radius: 50px;
  margin-top: 5%;
}

.qsortdone button{
  width: 100%!important;
  padding: 3%!important;
  margin-left: 2%!important;
  margin-right: 2%!important;
}

.button-sortdone{
  background-color: #ED6A1C!important;
    border-radius: 50px!important;
    padding: 2% 8%!important;

    font-weight: bold!important;
    border-color: #ED6A1C!important;
    
}

.button-restart{
  background-color: white!important;
    border-radius: 50px!important;
    padding: 2% 8%!important;
    color: #757575!important;
    font-weight: bold!important;
    border-color: #ED6A1F!important;
    
}




.estrellas {
  position: relative;
  width: 580px;
  height:300px;
  margin: 0 auto;
  font-size: 30px;
}
.estrellas span {
  color: white;
  position: absolute;
  bottom: 0;
  left: 47%;
  width: 10px;
  height: 215px;
  line-height: 140px; /* height*2 - font-size */
  text-align: center;
  transform-origin: top center;
}
.estrellas span:nth-of-type(1)  {
  transform: rotate(80deg);
}
.estrellas span:nth-of-type(2)  {
  transform: rotate(74.1deg);
}
.estrellas span:nth-of-type(3)  {
  transform: rotate(68.2deg);
}
.estrellas span:nth-of-type(4)  {
  transform: rotate(62.3deg);
}
.estrellas span:nth-of-type(5)  {
  transform: rotate(56.4deg);
}
.estrellas span:nth-of-type(6)  {
  transform: rotate(50.5deg);
}
.estrellas span:nth-of-type(7)  {
  transform: rotate(44.6deg);
}
.estrellas span:nth-of-type(8)  {
  transform: rotate(38.7deg);
}
.estrellas span:nth-of-type(9)  {
  transform: rotate(32.8deg);
}
.estrellas span:nth-of-type(10)  {
  transform: rotate(26.9deg);
}
.estrellas span:nth-of-type(11)  {
  transform: rotate(21deg);
}
.estrellas span:nth-of-type(12)  {
  transform: rotate(15.1deg);
}
.estrellas span:nth-of-type(13)  {
  transform: rotate(9.2deg);
}
.estrellas span:nth-of-type(14)  {
  transform: rotate(3.3deg);
}
.estrellas span:nth-of-type(15)  {
  transform: rotate(-2.6deg);
}
.estrellas span:nth-of-type(16)  {
  transform: rotate(-8.5deg);
}
.estrellas span:nth-of-type(17)  {
  transform: rotate(-14.4deg);
}
.estrellas span:nth-of-type(18)  {
  transform: rotate(-23.3deg);
}
.estrellas span:nth-of-type(19)  {
  transform: rotate(-26.2deg);
}
.estrellas span:nth-of-type(20)  {
  transform: rotate(-32.1deg);
}
.estrellas span:nth-of-type(21)  {
  transform: rotate(-38deg);
}
.estrellas span:nth-of-type(22)  {
  transform: rotate(-43.9deg);
}
.estrellas span:nth-of-type(23)  {
  transform: rotate(-49.8deg);
}
.estrellas span:nth-of-type(24)  {
  transform: rotate(-55.7deg);
}
.estrellas span:nth-of-type(25)  {
  transform: rotate(-62.6deg);
}
.estrellas span:nth-of-type(26)  {
  transform: rotate(-72.5deg);
}
.estrellas span:nth-of-type(27)  {
  transform: rotate(-80deg);
}


.page-header{
  position: relative;
  z-index: 999;
}

/* middle ring */
.middle {
  position: relative;
  width: 1280px;
  height:200px;
  margin: 0 auto;
  margin-top: -16%;
  font-size: 30px;
}
.middle span {
  color: white;
  position: absolute;
  bottom: 0;
  left: 47%;
  width: 30px;
  height: 405px;
  line-height: 100px; /* height*2 - font-size */
  text-align: center;
  transform-origin: top center;
}
.middle span:nth-of-type(1)  {
  transform: rotate(77.5deg);
}
.middle span:nth-of-type(2)  {
  transform: rotate(74deg);
}
.middle span:nth-of-type(3)  {
  transform: rotate(70.5deg);
}
.middle span:nth-of-type(4)  {
  transform: rotate(67deg);
}
.middle span:nth-of-type(5)  {
  transform: rotate(63.5deg);
}
.middle span:nth-of-type(6)  {
  transform: rotate(60deg);
}
.middle span:nth-of-type(7)  {
  transform: rotate(56.5deg);
}
.middle span:nth-of-type(8)  {
  transform: rotate(53deg);
}
.middle span:nth-of-type(9)  {
  transform: rotate(49.5deg);
}
.middle span:nth-of-type(10)  {
  transform: rotate(46deg);
}
.middle span:nth-of-type(11)  {
  transform: rotate(42.5deg);
}
.middle span:nth-of-type(12)  {
  transform: rotate(39deg);
}
.middle span:nth-of-type(13)  {
  transform: rotate(35.5deg);
}
.middle span:nth-of-type(14)  {
  transform: rotate(32deg);
}
.middle span:nth-of-type(15)  {
  transform: rotate(28.5deg);
}
.middle span:nth-of-type(16)  {
  transform: rotate(25deg);
}
.middle span:nth-of-type(17)  {
  transform: rotate(21.5deg);
}
.middle span:nth-of-type(18)  {
  transform: rotate(18deg);
}
.middle span:nth-of-type(19)  {
  transform: rotate(14.5deg);
}
.middle span:nth-of-type(20)  {
  transform: rotate(11deg);
}
.middle span:nth-of-type(21)  {
  transform: rotate(7.5deg);
}
.middle span:nth-of-type(22)  {
  transform: rotate(4deg);
}
.middle span:nth-of-type(23)  {
  transform: rotate(0.5deg);
}
.middle span:nth-of-type(24)  {
  transform: rotate(-3deg);
}
.middle span:nth-of-type(25)  {
  transform: rotate(-6.5deg);
}
.middle span:nth-of-type(26)  {
  transform: rotate(-10deg);
}
.middle span:nth-of-type(27)  {
  transform: rotate(-13.5deg);
}
.middle span:nth-of-type(28)  {
  transform: rotate(-17deg);
}
.middle span:nth-of-type(29)  {
  transform: rotate(-20.5deg);
}
.middle span:nth-of-type(30)  {
  transform: rotate(-24deg);
}
.middle span:nth-of-type(31)  {
  transform: rotate(-27.5deg);
}
.middle span:nth-of-type(32)  {
  transform: rotate(-31deg);
}
.middle span:nth-of-type(33)  {
  transform: rotate(-34.5deg);
}
.middle span:nth-of-type(34)  {
  transform: rotate(-38deg);
}
.middle span:nth-of-type(35)  {
  transform: rotate(-41.5deg);
}
.middle span:nth-of-type(36)  {
  transform: rotate(-45deg);
}
.middle span:nth-of-type(37)  {
  transform: rotate(-48.5deg);
}
.middle span:nth-of-type(38)  {
  transform: rotate(-52deg);
}
.middle span:nth-of-type(39)  {
  transform: rotate(-55.5deg);
}
.middle span:nth-of-type(40)  {
  transform: rotate(-59deg);
}
.middle span:nth-of-type(41)  {
  transform: rotate(-62.5deg);
}
.middle span:nth-of-type(42)  {
  transform: rotate(-66deg);
}
.middle span:nth-of-type(43)  {
  transform: rotate(-69.5deg);
}
.middle span:nth-of-type(44)  {
  transform: rotate(-73deg);
}
.middle span:nth-of-type(45)  {
  transform: rotate(-76.5deg);
}
.middle span:nth-of-type(46)  {
  transform: rotate(-80deg);
}

.outside {
  position: relative;
  width: 1280px;
  height:200px;
  margin: 0 auto;
  margin-top: -16%;
  font-size: 30px;
}
.outside span {
  color: white;
  position: absolute;
  bottom: 0;
  left: 47%;
  width: 30px;
  height: 592px;
  line-height: 100px; /* height*2 - font-size */
  text-align: center;
  transform-origin: top center;
}

.outside span:nth-of-type(1)  {
  transform: rotate(83deg);
}
.outside span:nth-of-type(2)  {
  transform: rotate(79.5deg);
}
.outside span:nth-of-type(3)  {
  transform: rotate(76deg);
}
.outside span:nth-of-type(4)  {
  transform: rotate(72.5deg);
}
.outside span:nth-of-type(5)  {
  transform: rotate(69deg);
}
.outside span:nth-of-type(6)  {
  transform: rotate(65.5deg);
}
.outside span:nth-of-type(7)  {
  transform: rotate(62deg);
}
.outside span:nth-of-type(8)  {
  transform: rotate(58.5deg);
}
.outside span:nth-of-type(9)  {
  transform: rotate(55deg);
}
.outside span:nth-of-type(10)  {
  transform: rotate(51.5deg);
}
.outside span:nth-of-type(11)  {
  transform: rotate(48deg);
}
.outside span:nth-of-type(12)  {
  transform: rotate(44.5deg);
}
.outside span:nth-of-type(13)  {
  transform: rotate(41deg);
}
.outside span:nth-of-type(14)  {
  transform: rotate(37.5deg);
}
.outside span:nth-of-type(15)  {
  transform: rotate(34deg);
}
.outside span:nth-of-type(16)  {
  transform: rotate(30.5deg);
}
.outside span:nth-of-type(17)  {
  transform: rotate(27deg);
}
.outside span:nth-of-type(18)  {
  transform: rotate(23.5deg);
}
.outside span:nth-of-type(19)  {
  transform: rotate(20deg);
}
.outside span:nth-of-type(20)  {
  transform: rotate(16.5deg);
}
.outside span:nth-of-type(21)  {
  transform: rotate(13deg);
}
.outside span:nth-of-type(22)  {
  transform: rotate(9.5deg);
}
.outside span:nth-of-type(23)  {
  transform: rotate(6deg);
}
.outside span:nth-of-type(24)  {
  transform: rotate(2.5deg);
}
.outside span:nth-of-type(25)  {
  transform: rotate(-1deg);
}
.outside span:nth-of-type(26)  {
  transform: rotate(-4.5deg);
}

.outside span:nth-of-type(27)  {
  transform: rotate(-8deg);
}
.outside span:nth-of-type(28)  {
  transform: rotate(-11.5deg);
}
.outside span:nth-of-type(29)  {
  transform: rotate(-15deg);
}
.outside span:nth-of-type(30)  {
  transform: rotate(-18.5deg);
}
.outside span:nth-of-type(31)  {
  transform: rotate(-22deg);
}
.outside span:nth-of-type(32)  {
  transform: rotate(-25.5deg);
}
.outside span:nth-of-type(33)  {
  transform: rotate(-29deg);
}
.outside span:nth-of-type(34)  {
  transform: rotate(-32.5deg);
}
.outside span:nth-of-type(35)  {
  transform: rotate(-36deg);
}
.outside span:nth-of-type(36)  {
  transform: rotate(-39.5deg);
}
.outside span:nth-of-type(37)  {
  transform: rotate(-43deg);
}
.outside span:nth-of-type(38)  {
  transform: rotate(-46.5deg);
}
.outside span:nth-of-type(39)  {
  transform: rotate(-50deg);
}
.outside span:nth-of-type(40)  {
  transform: rotate(-53.5deg);
}
.outside span:nth-of-type(41)  {
  transform: rotate(-57deg);
}
.outside span:nth-of-type(42)  {
  transform: rotate(-60.5deg);
}
.outside span:nth-of-type(43)  {
  transform: rotate(-64deg);
}
.outside span:nth-of-type(44)  {
  transform: rotate(-67.5deg);
}
.outside span:nth-of-type(45)  {
  transform: rotate(-71deg);
}
.outside span:nth-of-type(46)  {
  transform: rotate(-74.5deg);
}
.outside span:nth-of-type(47)  {
  transform: rotate(-78deg);
}
.outside span:nth-of-type(48)  {
  transform: rotate(-81.5deg);
}
.outside span:nth-of-type(49)  {
  transform: rotate(-85deg);
}


/* Reverse mode */
.inverso {
  font-weight: bold;
}
.inverso span {
  line-height: 80px;
  transform-origin: bottom center;
}

.category-one{
  position: absolute;
  width: 100px;
  text-align: center;
  color: white;
  left: 50%;
  top: 20%;
}

.category-one-three{
  position: absolute;
  width: 100px;
  text-align: center;
  color: white;
  left: 52%;
  top: 27%;
}

.category-two-three{
  position: absolute;
  width: 100px;
  text-align: center;
  color: white;
  left: 45%;
  top: 43%;
}

.category-third-three{
  position: absolute;
  width: 100px;
  text-align: center;
  color: white;
  left: 38%;
  top: 27%;
}

.category-one-four{
  position: absolute;
  width: 100px;
  text-align: center;
  color: white;
  left: 52%;
  top: 25%;
}

.category-one-five{
  position: absolute;
  width: 100px;
  text-align: center;
  color: white;
  left: 51%;
  top: 20%;
}

.category-one-seven{
  position: absolute;
  width: 100px;
  text-align: center;
  color: white;
  left: 49%;
  top: 20%;
}

.category-two{
  position: absolute;
  width: 100px;
  text-align: center;
  color: white;
  left: 54%;
  top: 34%;
}

.category-two-four{
  position: absolute;
  width: 100px;
  text-align: center;
  color: white;
  left: 51%;
  top: 40%;
}

.category-two-five{
  position: absolute;
  width: 100px;
  text-align: center;
  color: white;
  left: 53%;
  top: 35%;
}

.category-two-seven{
  position: absolute;
  width: 100px;
  text-align: center;
  color: white;
  left: 53%;
  top: 31%;
}

.category-third{
  position: absolute;
  width: 100px;
  text-align: center;
  color: white;
  left: 50%;
  top: 45%;
}

.category-third-four{
  position: absolute;
  width: 100px;
  text-align: center;
  color: white;
  left: 39%;
  top: 39%;
}

.category-third-five{
  position: absolute;
  width: 100px;
  text-align: center;
  color: white;
  left: 45%;
  top: 45%;
}

.category-third-seven{
  position: absolute;
  width: 100px;
  text-align: center;
  color: white;
  left: 53.5%;
  top: 42%;
}

.category-four{
  position: absolute;
  width: 153px;
  text-align: center;
  color: white;
  left: 37%;
  top: 45%;
}

.category-four-four{
  position: absolute;
  width: 153px;
  text-align: center;
  color: white;
  left: 37%;
  top: 25%;
}

.category-four-five{
  position: absolute;
  width: 153px;
  text-align: center;
  color: white;
  left: 34%;
  top: 36%;
}

.category-four-seven{
  position: absolute;
  width: 120px;
  text-align: center;
  color: white;
  left: 44%;
  top: 49%;
}

.category-five{
  position: absolute;
  width: 100px;
  text-align: center;
  color: white;
  left: 35%;
  top: 31%;
}

.category-five-five{
  position: absolute;
  width: 100px;
  text-align: center;
  color: white;
  left: 38%;
  top: 22%;
}

.category-five-seven{
  position: absolute;
  width: 100px;
  text-align: center;
  color: white;
  left: 37%;
  top: 42%;
}

.category-six{
  position: absolute;
  width: 100px;
  text-align: center;
  color: white;
  left: 40%;
  top: 20%;
}

.category-six-seven{
  position: absolute;
  width: 100px;
  text-align: center;
  color: white;
  left: 35%;
  top: 30%;
}

.category-seven-seven{
  position: absolute;
  width: 100px;
  text-align: center;
  color: white;
  left: 40.5%;
  top: 21.5%;
}

.e-checkbox-wrapper{
  margin-right: 2%;
  height: 10px;
}

.margin-bottom-min{
  margin-bottom: 0%!important;
}

.question-list{
  font-size: 12pt;

}

.post-it-view li{
  list-style: none;
  font-size: 12pt;

}
.see-list-delete{
  font-size: 12pt;
}

.delete-icon{
  color:red;
  float: right;
}

.no-participants{
  font-size: 12pt;
}