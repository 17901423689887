.main-content-container{
    background-color: #f7f7f7!important;
}

.settings-table{
    width: 100%;
    margin-left: 11%;
}
.settings-table tr th{
    padding: 1% 2%;
}
.settings-table tr td{
    background-color: #fff;
    padding: 1% 2%;
    width: 24%;
}

.settings-table tr td svg text{
font-size: 9pt!important;
}

.svg-initials text{
    text-transform: uppercase;
}

.settings-table tr:first-child{
    border-bottom-color: rgba(112,112,112,0.18);
    border-bottom-style: none;
    border-bottom-width: 1px;
}

.settings-table tr{
    border-bottom-color: rgba(112,112,112,0.18);
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.settings-table tr > td:first-child {
width:4%
}

.settings-table tr:nth-child(2) td:nth-child(1) {
    border-top-left-radius: 20px!important;
    padding: 2% 2% 1% 2%;
}

.settings-table tr:nth-child(2) td:nth-child(2) , .settings-table tr:nth-child(2) td:nth-child(3),.settings-table tr:nth-child(2) td:nth-child(4),.settings-table tr:nth-child(2) td:nth-child(5),.settings-table tr:nth-child(2) td:nth-child(6)   {
    border-top-left-radius: 0px!important;
    box-shadow: 0 9px 0px 0px white, 0 -9px 0px 0px white, 12px 0 15px -4px rgba(31, 73, 125, 0.8), -12px ;
}

.settings-table tr:nth-child(2) td:nth-child(5){
    border-top-right-radius: 20px!important;
} 

.list-item-selection li{
    border: 1px solid grey;
    border-radius: 5px;
    margin-bottom: 3%;
    list-style: none;
    width: 100%;
    padding: 2%;
    background-color: white;
}

.list-item-selection li .align-right{
    float: right;
}

.expanded-table-row{

    border-top-color: #FAFAFA;
    border-top-style: solid;
    border-top-width: 3px;
    border-left-color: #E74F28;
    border-left-style: solid;
    border-left-width: 3px;
}
.active{
    border-left-color: #E74F28;
    border-left-style: solid;
    border-left-width: 3px;
}
.active td{
    background-color: #FAFAFA!important;
}

.active-svg .right-svg-icon{
    transform: rotate(180deg);
}

.expanded-table-row td{
    background-color: #FAFAFA!important;
}

.expanded-table-item{
    vertical-align:top;
    padding-bottom: 3%!important;
}

.expanded-table-item b{
    font-weight: bold;
    padding-bottom: 3%!important;
}

.end-of-table{
    background-color: #fff!important;
    width: 100%;
    text-align: center;
    padding: 1% 2% 2% 2%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    font-weight: 800;
    margin-left: 11%;
    margin-bottom: 8%;
}

.category-circle{
    background-color: teal;
    border-radius: 50px;
    padding: 1% 5%;
}

.svg-icon{
    width: 5%;
    margin-right: 12%;
    float: left;
}

.right-svg-icon{
    float: right;
}

.input-field-sets{
    background-color: white;
    padding: 9% 14%;
    width: 150%;
    right: 0;
    margin-left: 50%;
    border-radius: 50px;
    border-style: solid;
    border-color: gray;
    border-width: 1px;
}

.button-settings{
    background-color: white;
    padding: 2% 2%;
    width: 100%;
    border-color:#e74f28;
    border-style: solid;
    border-width: 1px;
    text-align: center;
    margin-bottom: 5%;
}

.button-settings:hover, .button-settings:hover a{
    background-color: #e74f28;
    color:white;
}

.button-settings a{
    color: #5EBDE9;
    text-decoration: none;
}

.left{
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.right{
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.active-settings{
    background-color: #e74f28;
    color:white;
}

.active-settings a{
    color: white;
}

.link-to-post{
    color:white;
}



.board-locked{

    background-color: red!important;
    color: white!important;

}


.board-closed{
    color: white;
}

.board-locked p span.material-icons{
    padding-top: 5%;
}

.icon-margin{
    float: left;
}

.hexagon {
    position: relative;
    width: 150px; 
    height: 86.60px;
    background-color: #6e6e6e2a;
  
  }
  
  .hexagon:before,
  .hexagon:after {
    content: "";
    position: absolute;
    width: 0;
    border-left: 75px solid transparent;
    border-right: 75px solid transparent;
  }
  
  .hexagon:before {
    bottom: 100%;
    border-bottom: 43.30px solid #6e6e6e2a;
    left: 0;
  }


  
  .hexagon:after {
    top: 100%;
    width: 0;
    border-top: 43.30px solid #6e6e6e2a;
    left:0;
  }

  .thema-x:after{
    border-top: 43.30px solid #ed6520!important;;
  }

  .thema-x:before {

    border-bottom: 43.30px solid #ed6520!important;

  }

  
  .thema-x{
      background-color: #ed6520!important;
  }




  #one-hex{
margin-left: 20%;
  }

  #two-hex{
    margin-left: 13.9%;
    margin-top: -17.5%;
}

#three-hex{
    margin-left: 26.2%;
    margin-top: -6.9%;
}
#four-hex{
    margin-left: 32.3%;
    margin-top: 3.7%;
}

#five-hex{
    margin-left: 26.1%;
    margin-top: 3.7%;
}

#six-hex{
    margin-left: 13.8%;
    margin-top: -7%;
}

#seven-hex{
    margin-left: 7.7%;
    margin-top: -17.5%;
}
#eight-hex{
    margin-left: 1.6%;
    margin-top: -17.7%;
}
#nine-hex{
    margin-left: 7.8%;
    margin-top: -17.5%;
}

#ten-hex{
    margin-left: 20%;
    margin-top: -6.8%;
}
#eleven-hex{
    margin-left: 32.3%;
    margin-top: -6.9%;
}

#twelve-hex{
    margin-left: 38.5%;
    margin-top: 3.7%;
}
#thirdteen-hex{
    margin-left: 44.6%;
    margin-top: 3.7%;
}

#thirdteen-hex{
    margin-left: 44.6%;
    margin-top: 3.7%;
}

#fourthteen-hex{
    margin-left: 38.4%;
    margin-top: 3.7%;
}

#fiveteen-hex{
    margin-left: 32.2%;
    margin-top: 3.7%;
}
#sixteen-hex{
    margin-left: 19.9%;
    margin-top: -6.9%;
}

#seventeen-hex{
    margin-left: 7.6%;
    margin-top: -7%;
}

#eigthteen-hex{
    margin-left: 1.5%;
    margin-top: -17.6%;
}

#nineteen-hex{
    margin-left: -4.6%;
    margin-top: -17.6%;
}

#twentyone-hex{
    margin-left: -10.7%;
    margin-top: -17.6%;
}
#twentytwo-hex{
    margin-left: -4.6%;
    margin-top: -17.6%;
}

#twentythree-hex{
    margin-left: 1.6%;
    margin-top: -17.6%;
}
#twentyfour-hex{
    margin-left: 13.9%;
    margin-top: -6.9%;
}

#twentyfive-hex{
    margin-left: 26.2%;
    margin-top: -6.9%;
}

#twentysix-hex{
    margin-left: 38.5%;
    margin-top: -6.9%;
}

#twentyseven-hex{
    margin-left: 44.7%;
    margin-top: 3.8%;
}

#twentyeight-hex{
    margin-left: 50.8%;
    margin-top: 3.9%;
}

#twentynine-hex{
    margin-left: 56.8%;
    margin-top: 3.7%;
}

#thirty-hex{
    margin-left: 50.65%;
    margin-top: 3.6%;
}
#thirtyone-hex{
    margin-left: 44.5%;
    margin-top: 3.6%;
}

#thirtytwo-hex{
    margin-left: 38.4%;
    margin-top: 3.7%;
}

#thirtythree-hex{
    margin-left: 26.1%;
    margin-top: -6.9%;
}

#thirtyfour-hex{
    margin-left: 13.8%;
    margin-top: -6.9%;
}


#thirtyfive-hex{
    margin-left: 1.5%;
    margin-top: -6.9%;
}

#thirtysix-hex{
    margin-left: -4.7%;
    margin-top: -17.6%;
}

#thirtysix-hex{
    margin-left: -4.7%;
    margin-top: -17.6%;
}
#thirtyseven-hex{
    margin-left: -10.7%;
    margin-top: -17.6%;
}

#thirtyeight-hex{
    margin-left: -16.9%;
    margin-top: -17.6%;
}

#thirtynine-hex{
  margin-left: -29.2%;
  margin-top: -6.8%;
}
#fourteen-hex{
  margin-left: -23.1%;
  margin-top: -17.6%;
}
#fourteenone-hex{
  margin-left: -17%;
  margin-top: -17.6%;
}
#fourteentwo-hex{
  margin-left: -10.8%;
  margin-top: -17.6%;
}
#fourteenthree-hex{
  margin-left: -4.8%;
  margin-top: -17.6%;
}
#fourteenfour-hex{
  margin-left: 7.8%;
  margin-top: -6.9%;
}
#fourteenfive-hex{
  margin-left: 20%;
  margin-top: -6.9%;
}
#fourteensix-hex{
  margin-left: 32.3%;
  margin-top: -6.9%;
}
#fourteenseven-hex{
  margin-left: 44.6%;
  margin-top: -6.9%;
}
#fourteeneight-hex{
  margin-left: 50.8%;
  margin-top: 3.65%;
}

#fourteennine-hex{
  margin-left: 57.1%;
  margin-top: 3.8%;
}

#fiftyy-hex{
  margin-left: 63%;
  margin-top: 3.9%;
}
#fiftyyone-hex{
  margin-left: 69.2%;
  margin-top: 3.7%;
}
#fiftyytwo-hex{
  margin-left: 63%;
  margin-top: 3.5%;
}
#fiftyythree-hex{
  margin-left: 56.8%;
  margin-top: 3.7%;
}
#fiftyyfour-hex{
  margin-left: 50.7%;
  margin-top: 3.6%;
}
#fiftyyfive-hex{
  margin-left: 44.6%;
  margin-top: 3.7%;
}
#fiftyysix-hex{
  margin-left: 32.3%;
  margin-top: -6.9%;
}
#fiftyyseven-hex{
  margin-left: 19.9%;
  margin-top: -6.9%;
}
#fiftyyeight-hex{
  margin-left: 7.7%;
  margin-top: -6.9%;
}
#fiftyynine-hex{
  margin-left: -4.6%;
  margin-top: -6.9%;
}
#sixty-hex{
  margin-left: -10.8%;
  margin-top: -17.5%;
}
#sixtyone-hex{
  margin-left: -17%;
  margin-top: -17.65%;
}
#sixtytwo-hex{
  margin-left: -23%;
  margin-top: -17.65%;
}
.mensen{
    background-color: #848d8e!important;
}

.mensen:after{
    border-top: 43.30px solid #848d8e!important;;
  }

  .mensen:before {

    border-bottom: 43.30px solid #848d8e!important;

  }

.technologie{
    background-color: #ed6520!important;
}

.technologie:after{
    border-top: 43.30px solid #ed6520!important;;
  }

  .technologie:before {

    border-bottom: 43.30px solid #ed6520!important;

  }

.proces{
    background-color: #887e20!important;
}

.proces:after{
    border-top: 43.30px solid #887e20!important;;
  }

  .proces:before {

    border-bottom: 43.30px solid #887e20!important;

  }

.people-baseline{
    background-color: #822ec6!important;
}

.people-baseline:after{
    border-top: 43.30px solid #822ec6!important;;
  }

  .people-baseline:before {

    border-bottom: 43.30px solid #822ec6!important;

  }

.practice-baseline{
    background-color: #4edca6!important;
}

.practice-baseline:after{
    border-top: 43.30px solid #4edca6!important;;
  }

  .practice-baseline:before {

    border-bottom: 43.30px solid #4edca6!important;

  }
.perspective-baseline{
    background-color: #80f4f7!important;
}
.perspective-baseline:after{
    border-top: 43.30px solid #80f4f7!important;;
  }

  .perspective-baseline:before {

    border-bottom: 43.30px solid #80f4f7!important;

  }

.sustainable{
    background-color: #da3636!important;
}

.sustainable:after{
    border-top: 43.30px solid #da3636!important;;
  }

  .sustainable:before {

    border-bottom: 43.30px solid #da3636!important;

  }

.why-mvi{
    background-color: #39e70d!important;
  
}

.why-mvi a{
  color:black!important;
  font-size: 9pt;
}


.why-mvi:after{
    border-top: 43.30px solid #39e70d!important;;
  }

  .why-mvi:before {

    border-bottom: 43.30px solid #39e70d!important;

  }

.principes-mvi{
    background-color: #cbe5b5!important;
}

.principes-mvi:after{
    border-top: 43.30px solid #cbe5b5!important;;
  }

  .principes-mvi:before {

    border-bottom: 43.30px solid #cbe5b5!important;

  }

.drijfveren-mvi{
    background-color: #71b91c!important;
}

.drijfveren-mvi:after{
    border-top: 43.30px solid #71b91c!important;;
  }

  .drijfveren-mvi:before {

    border-bottom: 43.30px solid #71b91c!important;

  }

.overwegingen-mvi {
    background-color: #1dca1f!important;
}

.overwegingen-mvi :after{
    border-top: 43.30px solid #1dca1f!important;;
  }

  .overwegingen-mvi :before {

    border-bottom: 43.30px solid #1dca1f!important;

  }




.prince-2-principes{
    background-color: #099b79!important;
}
.prince-2-principes:after{
    border-top: 43.30px solid #099b79!important;;
  }

  .prince-2-principes:before {

    border-bottom: 43.30px solid #099b79!important;

  }

.prince-2-themas{
    background-color: #cf1ac1!important;
}
.prince-2-themas:after{
    border-top: 43.30px solid #cf1ac1!important;;
  }

  .prince-2-themas:before {

    border-bottom: 43.30px solid #cf1ac1!important;

  }

.prince-2-processen{
    background-color: #dd6b0c!important;
}

.prince-2-processen:after{
    border-top: 43.30px solid #dd6b0c!important;;
  }

  .prince-2-processen:before {

    border-bottom: 43.30px solid #dd6b0c!important;

  }

.thema-mvi{
    background-color: #af610a!important;
}
.thema-mvi:after{
    border-top: 43.30px solid #af610a!important;;
  }

  .thema-mvi:before {

    border-bottom: 43.30px solid #af610a!important;

  }

.belbin {
    background-color: #2d27d4!important;
}
.belbin:after{
    border-top: 43.30px solid #2d27d4!important;;
  }

  .belbin:before {

    border-bottom: 43.30px solid #2d27d4!important;

  }

  .fasemodel {
    background-color: #d8f506!important;

}
.fasemodel:after{
    border-top: 43.30px solid #d8f506!important;;
  }

  .fasemodel a{
    color:black!important;
  }

  .fasemodel:before {

    border-bottom: 43.30px solid #d8f506!important;

  }
  .belemmeringen {
    background-color: #1ea1af!important;
}
.belemmeringen:after{
    border-top: 43.30px solid #1ea1af!important;;
  }

  .belemmeringen:before {

    border-bottom: 43.30px solid #1ea1af!important;

  }

  .kansen {
    background-color: #5e63c4!important;
}
.kansen:after{
    border-top: 43.30px solid #5e63c4!important;;
  }

  .kansen:before {

    border-bottom: 43.30px solid #5e63c4!important;

  }

  .toekomst {
    background-color: #f5d94c!important;
}
.toekomst:after{
    border-top: 43.30px solid #f5d94c!important;;
  }

  .toekomst:before {

    border-bottom: 43.30px solid #f5d94c!important;

  }

  .toekomst a{
    color:black!important;
  }

  .centralpoint img{
      margin-top: 3%;
      width: 70%!important;
      margin-left: 15%;
  }

  .centralpoint p{
      text-align: center;
      margin-top: 8%;
  }

  .icons-board{
      width: 100%;
      float: left;

  }

  .icons-board i{
      margin-right: 2%;
      margin-left: 2%;
  }

  .fa-eye{
      color: white;
  }

  .fa-trash-alt{
      color:rgb(146, 7, 7)
  }

  .add-item-row{
    position: fixed;
    z-index: 99;
    /* margin-top: -59%; */
    background-color: black;
    margin-left: -2%!important;
    width: 104%;
    z-index: 9999;
    height: 100%;
    top: 0;
  }

  .show-item-row{
    position: absolute;
    z-index: 99;
    /* margin-top: -59%; */
    background-color: wheat;
    margin-left: -2%!important;
    width: 104%;

    z-index: 9999;
    height: 200%;
    top: 0;
  }
  .hover-text{
    color: white;
    font-size: 10pt;
  }
  .hover-text:hover{
cursor: pointer;
text-decoration: underline;
color: rgba(218, 218, 218, 0.932);
  }

  .add-item-col{
      background-color: white;
      height: 100%;
      margin-top:15%;
      padding: 5% 5%!important;
  }

  .show-item-col{
    background-color: white;

    margin-top:5%;
    padding: 5% 5%!important;
  }

  .image-sdg{
    width: 50%;
    margin-right: 25%;
    margin-left: 25%;
    margin-top: -13%;
    position: relative;
  }

  .hexagon{
      color: whitesmoke;
  }

  .sdg-one {
    background-color: #e5243b!important;
}
.sdg-one:after{
    border-top: 43.30px solid #e5243b!important;;
  }

  .sdg-one:before {

    border-bottom: 43.30px solid #e5243b!important;

  }

  .sdg-two {
    background-color: #e5b735!important;
}
.sdg-two:after{
    border-top: 43.30px solid #e5b735!important;;
  }

  .sdg-two:before {

    border-bottom: 43.30px solid #e5b735!important;

  }

  .sdg-three {
    background-color: #4c9f38!important;
}
.sdg-three:after{
    border-top: 43.30px solid #4c9f38!important;;
  }

  .sdg-three:before {

    border-bottom: 43.30px solid #4c9f38!important;

  }

  .sdg-four {
    background-color: #c5192d!important;
}
.sdg-four:after{
    border-top: 43.30px solid #c5192d!important;;
  }

  .sdg-four:before {

    border-bottom: 43.30px solid #c5192d!important;

  }

  .sdg-five {
    background-color: #ff3a21!important;
}
.sdg-five:after{
    border-top: 43.30px solid #ff3a21!important;;
  }

  .sdg-five:before {

    border-bottom: 43.30px solid #ff3a21!important;

  }

  .sdg-six {
    background-color: #26bde2!important;
}
.sdg-six:after{
    border-top: 43.30px solid #26bde2!important;;
  }

  .sdg-six:before {

    border-bottom: 43.30px solid #26bde2!important;

  }

  .sdg-seven {
    background-color: #fcc30b!important;
}
.sdg-seven:after{
    border-top: 43.30px solid #fcc30b!important;;
  }

  .sdg-seven:before {

    border-bottom: 43.30px solid #fcc30b!important;

  }

  .sdg-eight{
    background-color: #a21942!important;
}
.sdg-eight:after{
    border-top: 43.30px solid #a21942!important;;
  }

  .sdg-eight:before {

    border-bottom: 43.30px solid #a21942!important;

  }

  .sdg-nine{
    background-color: #fd6925!important;
}
.sdg-nine:after{
    border-top: 43.30px solid #fd6925!important;;
  }

  .sdg-nine:before {

    border-bottom: 43.30px solid #fd6925!important;

  }

  .sdg-ten{
    background-color: #dd1367!important;
}
.sdg-ten:after{
    border-top: 43.30px solid #dd1367!important;;
  }

  .sdg-ten:before {

    border-bottom: 43.30px solid #dd1367!important;

  }

  .sdg-eleven{
    background-color: #fd9f24!important;
}
.sdg-eleven:after{
    border-top: 43.30px solid #fd9f24!important;;
  }

  .sdg-eleven:before {

    border-bottom: 43.30px solid #fd9f24!important;

  }

  .sdg-twelve{
    background-color: #c9992d!important;
}
.sdg-twelve:after{
    border-top: 43.30px solid #c9992d!important;;
  }

  .sdg-twelve:before {

    border-bottom: 43.30px solid #c9992d!important;

  }

  .sdg-thirtheen{
    background-color: #3f7e44!important;
}
.sdg-thirtheen:after{
    border-top: 43.30px solid #3f7e44!important;;
  }

  .sdg-thirtheen:before {

    border-bottom: 43.30px solid #3f7e44!important;

  }

  .sdg-fourteen{
    background-color: #0a97d9!important;
}
.sdg-fourteen:after{
    border-top: 43.30px solid #0a97d9!important;;
  }

  .sdg-fourteen:before {

    border-bottom: 43.30px solid #0a97d9!important;

  }

  .sdg-fiveteen{
    background-color: #56c02b!important;
}
.sdg-fiveteen:after{
    border-top: 43.30px solid #56c02b!important;;
  }

  .sdg-fiveteen:before {

    border-bottom: 43.30px solid #56c02b!important;

  }

  .sdg-sixteen{
    background-color: #00689d!important;
}
.sdg-sixteen:after{
    border-top: 43.30px solid #00689d!important;;
  }

  .sdg-sixteen:before {

    border-bottom: 43.30px solid #00689d!important;

  }

  .score{
    background-color: #0ca046!important;
}
.score:after{
    border-top: 43.30px solid #0ca046!important;;
  }

  .score:before {

    border-bottom: 43.30px solid #0ca046!important;

  }

  .belemmeringen-hkt{
    background-color: #ac4cf5!important;
}
.belemmeringen-hkt:after{
    border-top: 43.30px solid #ac4cf5!important;;
  }

  .belemmeringen-hkt:before {

    border-bottom: 43.30px solid #ac4cf5!important;

  }

  .kansen-hkt{
    background-color: #f5b042!important;
}
.kansen-hkt:after{
    border-top: 43.30px solid #f5b042!important;;
  }

  .kansen-hkt:before {

    border-bottom: 43.30px solid #f5b042!important;

  }

  .sdg-seventeen{
    background-color: #19486a!important;
}
.sdg-seventeen:after{
    border-top: 43.30px solid #19486a!important;;
  }

  .sdg-seventeen:before {

    border-bottom: 43.30px solid #19486a!important;

  }

  .add-product-colum h2{
      margin-top: 5%;
      margin-bottom: 3%;
  }

  .add-product-colum{
      padding-bottom: 35%;
  }
  .settings-balk{
    margin-top: -5%;
    margin-bottom: 10%;
    background-color: white;
    height: 120px;
  }

  .settings-balk button{
    float: left;
    margin-top: 2.2%;
    margin-right: 1%;
  }


  .settings-balk select,  .settings-balk label{
    float: right;
  }
  .settings-balk label{
    margin-top: 10px;
  }

  .settings-balk select{
    padding: 1%!important;
  }

  .item-cols{
    margin-top: -10%;
    margin-left: -10%;
  }